<template>
<section class="admin-section">
    <div class="app-main__inner">
      <div class="row">
          <div class="col-md-12 col-sm-12 fullWidth">
              <div class="app-page-title dashboard-title">
                  <div class="page-title-wrapper">
                      <div class="page-title-heading">
                          <div class="page-title-icon">
                              <i class="pe-7s-video icon-gradient bg-happy-itmeo">
                              </i>
                          </div>
                          <div>Premium Videos</div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <div class="row">
        <div class="col-md-9 col-lg-9">
            <template >
            <div class="pt-3 pb-3" >
               <b-overlay :show="showOverLap">
                    <!-- <video controls="true" style="width: 100%;" controlsList="nodownload">
          <source :src="activeVideo" type="video/mp4">
        </video> -->
        <video-embed tag="div" :params="{showinfo: 0}" :src="activeVideo" style="height: 600px;border-radius: 10px;border: none;width: 100%;" v-if="!showOverLap" autoplay controls="true" :key="activeTitle" controlsList="nodownload"></video-embed>
        <video-embed tag="div" :params="{showinfo: 0}" :src="activeVideo" style="height: 600px;border-radius: 10px;border: none;width: 100%;" v-if="showOverLap" controls="true" :key="activeTitle" controlsList="nodownload"></video-embed>
        
        <!-- <iframe frameborder="0" allowfullscreen allowtransparency style="height: 600px;border-radius: 10px;border: none;width: 100%;" :src="activeVideo" v-if="!showOverLap" autoplay controls="true" :key="activeTitle" controlsList="nodownload">
          
                    </iframe>
        <iframe frameborder="0" allowfullscreen allowtransparency style="height: 600px;border-radius: 10px;border: none;width: 100%;" :src="activeVideo" v-if="showOverLap" controls="true" :key="activeTitle" controlsList="nodownload">
        
        </iframe> -->

                <template #overlay>
                <div class="text-center">
                <b-icon icon="lock" font-scale="3" animation="cylon"></b-icon>
            
                <h4 class="mt-2" id="cancel-label">
                    For premium users</h4>
                </div>
                </template>
           
            <h5 class="mt-2 mb-2">
                <strong>{{activeTitle}}</strong>  </h5>
                <div class="text-right">
                    <p  class="likeSpan"> 
                    <a @click="saveCountLike('like',disableLikeButton)" :class="{ 'disabled' : disableLikeButton}" :disabled="disableLikeButton"><b-icon icon="hand-thumbs-up"  font-scale="1.4" ></b-icon></a>
                       <span> {{ likes }}</span> 
                     <a @click="saveCountLike('dislike',disableDislikeButton)" :class="{ 'disabled' : disableDislikeButton}" :disabled="disableDislikeButton"><b-icon class="leftBorder pl-1" icon="hand-thumbs-down"  font-scale="1.6" ></b-icon>
                     </a><span>{{ disLikes }}</span>
                    </p>
                </div>
            </b-overlay>
            </div>
            
        </template>
      
        <div class="CommentsContainer">
        <div class="" style="text-align: left">
      <h6 style="text-align: left">{{ userComments.length }} Comments</h6>
      <hr/>
      <textarea
        id="description"
        name="description"
        rows="3"
        v-model.trim="commentInput"
        placeholder="Enter Comment..."
      ></textarea>
      <br />
      <button class="btn btn-outline-primary p-2 pl-4 pr-4" style="float: right;" @click="submitData">Post Comments</button>
    </div></div>
    <div>
        <br />
        <br/>
        <hr/>
    <p
      v-for="userComment in userComments"
      :key="userComment.id"
      :class="{ 'admin-comment': isAdminComment(userComment.isAdmin) }"
      class="comment-container"
    >
      <span class="comment-content">
        <b>{{ userComment.userName }}: </b>{{ userComment.comment }}
      </span>
      <a @click="deleteComment(userComment.id)" class="commentDelete">
        <b-icon icon="trash-fill" font-scale="1" ></b-icon>
      </a>
    </p>
    <hr/>
      <!-- <span v-if="isCurrentUserComment(userComment.userId)">
        <a class="vertical-dots"><b>...</b></a></span> -->
  </div>
        </div>

        <div class="col-md-3 col-lg-3 pt-3" style="max-height: 90vh;overflow-y: auto;">
            <div class="row mt-2 ml-2 mr-2 fullWidth">
        <template v-for="(item) in youTubeList">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 pb-3 pl-0" :key="item.id">
                <b-overlay :show="showOverLap">
                    <b-overlay :show="!showOverLap" id="playOverlay" @click="playSelectedVideo(item)">
                        <!-- <video controls="true" style="width: 100%;" controlsList="nodownload">
                            <source :src="basePath+item.url" type="video/mp4">
                            </video> -->
                            <img :src="'https://i.ytimg.com/vi/'+item.url+'/mqdefault.jpg'" class="youtubeImg">
                        <template #overlay>
                            <div class="text-center">
                                <b-icon icon="play-fill" font-scale="3" variant="danger"></b-icon>
                                </div>
                        </template>
                    </b-overlay>
                    
                    <!-- :params="{showinfo: 0}" -->
                <!-- <video-embed  :src="item.url"></video-embed> -->
                <template #overlay>
                <div class="text-center">
                <b-icon icon="lock" font-scale="3" animation="cylon"></b-icon>
            
                <h4 class="mt-2" id="cancel-label">
                    For premium users</h4>
                </div>
                </template>
            </b-overlay>
            <h6 class="mt-2 mb-2">
                {{item.title}}</h6>
            </div>
        </template>
    </div>

        </div>
      </div>
     
    </div>
</section>
</template>

<script>
import { mapActions,mapGetters } from 'vuex'
export default {
    props:['showOverLap'],
    data(){
        return{
            disableLikeButton: false,
            disableDislikeButton: false,
            videoDetail: [],
            inputIsInvalid: false,
            id: "",
            videoLink: "",
            videoTitle: "",
            desc: "",
            link: "",
            commentInput:"",
            userComments: [],
            status: false,
            basePath:'https://youtu.be/',
            activeVideo:"",
            activeTitle:"",
            likes:0,
            disLikes:0,
            userInfo:null,
            urlArray:[],
            youTubeList:[
        {
            "kind": "youtube#playlistItem",
            "etag": "DEZacwHstKgwJwZILcvFUMDPdyg",
            "id": "UExnSjJ5dFpLZlltaWd2VlRGV3RmZFFQYlh3dE8wV3RjdS41NkI0NEY2RDEwNTU3Q0M2",
            "snippet": {
                "publishedAt": "2024-03-10T07:42:48Z",
                "channelId": "UC9BNqxAvzT-AYaKByLPKJfA",
                "title": "Power Session On Grammar Day 1",
                "description": "",
                "thumbnails": {
                    "default": {
                        "url": "https://i.ytimg.com/vi/-X5bLJW0AUw/default.jpg",
                        "width": 120,
                        "height": 90
                    },
                    "medium": {
                        "url": "https://i.ytimg.com/vi/-X5bLJW0AUw/mqdefault.jpg",
                        "width": 320,
                        "height": 180
                    },
                    "high": {
                        "url": "https://i.ytimg.com/vi/-X5bLJW0AUw/hqdefault.jpg",
                        "width": 480,
                        "height": 360
                    },
                    "standard": {
                        "url": "https://i.ytimg.com/vi/-X5bLJW0AUw/sddefault.jpg",
                        "width": 640,
                        "height": 480
                    },
                    "maxres": {
                        "url": "https://i.ytimg.com/vi/-X5bLJW0AUw/maxresdefault.jpg",
                        "width": 1280,
                        "height": 720
                    }
                },
                "channelTitle": "PTEClasses",
                "playlistId": "PLgJ2ytZKfYmigvVTFWtfdQPbXwtO0Wtcu",
                "position": 0,
                "resourceId": {
                    "kind": "youtube#video",
                    "videoId": "-X5bLJW0AUw"
                },
                "videoOwnerChannelTitle": "PTEClasses",
                "videoOwnerChannelId": "UC9BNqxAvzT-AYaKByLPKJfA"
            },
            "contentDetails": {
                "videoId": "-X5bLJW0AUw",
                "videoPublishedAt": "2024-03-10T07:38:57Z"
            }
        },
        {
            "kind": "youtube#playlistItem",
            "etag": "2jh25WeIKeD-RKB7z4vN0IzUxq8",
            "id": "UExnSjJ5dFpLZlltaWd2VlRGV3RmZFFQYlh3dE8wV3RjdS4yODlGNEE0NkRGMEEzMEQy",
            "snippet": {
                "publishedAt": "2024-03-10T07:42:48Z",
                "channelId": "UC9BNqxAvzT-AYaKByLPKJfA",
                "title": "Practice Questions   Fill In The Blanks",
                "description": "",
                "thumbnails": {
                    "default": {
                        "url": "https://i.ytimg.com/vi/1ree_TpJW68/default.jpg",
                        "width": 120,
                        "height": 90
                    },
                    "medium": {
                        "url": "https://i.ytimg.com/vi/1ree_TpJW68/mqdefault.jpg",
                        "width": 320,
                        "height": 180
                    },
                    "high": {
                        "url": "https://i.ytimg.com/vi/1ree_TpJW68/hqdefault.jpg",
                        "width": 480,
                        "height": 360
                    },
                    "standard": {
                        "url": "https://i.ytimg.com/vi/1ree_TpJW68/sddefault.jpg",
                        "width": 640,
                        "height": 480
                    },
                    "maxres": {
                        "url": "https://i.ytimg.com/vi/1ree_TpJW68/maxresdefault.jpg",
                        "width": 1280,
                        "height": 720
                    }
                },
                "channelTitle": "PTEClasses",
                "playlistId": "PLgJ2ytZKfYmigvVTFWtfdQPbXwtO0Wtcu",
                "position": 1,
                "resourceId": {
                    "kind": "youtube#video",
                    "videoId": "1ree_TpJW68"
                },
                "videoOwnerChannelTitle": "PTEClasses",
                "videoOwnerChannelId": "UC9BNqxAvzT-AYaKByLPKJfA"
            },
            "contentDetails": {
                "videoId": "1ree_TpJW68",
                "videoPublishedAt": "2024-03-10T07:34:45Z"
            }
        },
        {
            "kind": "youtube#playlistItem",
            "etag": "Q8FWpSZb_99x-8Jsr-3FDfhrp8w",
            "id": "UExnSjJ5dFpLZlltaWd2VlRGV3RmZFFQYlh3dE8wV3RjdS4wMTcyMDhGQUE4NTIzM0Y5",
            "snippet": {
                "publishedAt": "2024-03-10T07:42:48Z",
                "channelId": "UC9BNqxAvzT-AYaKByLPKJfA",
                "title": "PPT Low virsion",
                "description": "",
                "thumbnails": {
                    "default": {
                        "url": "https://i.ytimg.com/vi/9AiP2MlkwTc/default.jpg",
                        "width": 120,
                        "height": 90
                    },
                    "medium": {
                        "url": "https://i.ytimg.com/vi/9AiP2MlkwTc/mqdefault.jpg",
                        "width": 320,
                        "height": 180
                    },
                    "high": {
                        "url": "https://i.ytimg.com/vi/9AiP2MlkwTc/hqdefault.jpg",
                        "width": 480,
                        "height": 360
                    },
                    "standard": {
                        "url": "https://i.ytimg.com/vi/9AiP2MlkwTc/sddefault.jpg",
                        "width": 640,
                        "height": 480
                    }
                },
                "channelTitle": "PTEClasses",
                "playlistId": "PLgJ2ytZKfYmigvVTFWtfdQPbXwtO0Wtcu",
                "position": 2,
                "resourceId": {
                    "kind": "youtube#video",
                    "videoId": "9AiP2MlkwTc"
                },
                "videoOwnerChannelTitle": "PTEClasses",
                "videoOwnerChannelId": "UC9BNqxAvzT-AYaKByLPKJfA"
            },
            "contentDetails": {
                "videoId": "9AiP2MlkwTc",
                "videoPublishedAt": "2024-03-10T07:39:08Z"
            }
        },
        {
            "kind": "youtube#playlistItem",
            "etag": "BsmLS0VFrazVmC9q40JuOuGE9Eg",
            "id": "UExnSjJ5dFpLZlltaWd2VlRGV3RmZFFQYlh3dE8wV3RjdS41MjE1MkI0OTQ2QzJGNzNG",
            "snippet": {
                "publishedAt": "2024-03-10T07:42:48Z",
                "channelId": "UC9BNqxAvzT-AYaKByLPKJfA",
                "title": "Grammar Power Session   Prefix and Suffix",
                "description": "",
                "thumbnails": {
                    "default": {
                        "url": "https://i.ytimg.com/vi/BUMtfw1fgM0/default.jpg",
                        "width": 120,
                        "height": 90
                    },
                    "medium": {
                        "url": "https://i.ytimg.com/vi/BUMtfw1fgM0/mqdefault.jpg",
                        "width": 320,
                        "height": 180
                    },
                    "high": {
                        "url": "https://i.ytimg.com/vi/BUMtfw1fgM0/hqdefault.jpg",
                        "width": 480,
                        "height": 360
                    },
                    "standard": {
                        "url": "https://i.ytimg.com/vi/BUMtfw1fgM0/sddefault.jpg",
                        "width": 640,
                        "height": 480
                    },
                    "maxres": {
                        "url": "https://i.ytimg.com/vi/BUMtfw1fgM0/maxresdefault.jpg",
                        "width": 1280,
                        "height": 720
                    }
                },
                "channelTitle": "PTEClasses",
                "playlistId": "PLgJ2ytZKfYmigvVTFWtfdQPbXwtO0Wtcu",
                "position": 3,
                "resourceId": {
                    "kind": "youtube#video",
                    "videoId": "BUMtfw1fgM0"
                },
                "videoOwnerChannelTitle": "PTEClasses",
                "videoOwnerChannelId": "UC9BNqxAvzT-AYaKByLPKJfA"
            },
            "contentDetails": {
                "videoId": "BUMtfw1fgM0",
                "videoPublishedAt": "2024-03-10T07:38:23Z"
            }
        },
        {
            "kind": "youtube#playlistItem",
            "etag": "ImXM9ijS9gFCVJw-09skXmkWvc0",
            "id": "UExnSjJ5dFpLZlltaWd2VlRGV3RmZFFQYlh3dE8wV3RjdS4wOTA3OTZBNzVEMTUzOTMy",
            "snippet": {
                "publishedAt": "2024-03-10T07:42:48Z",
                "channelId": "UC9BNqxAvzT-AYaKByLPKJfA",
                "title": "Fill in the Blanks Drop down Question",
                "description": "",
                "thumbnails": {
                    "default": {
                        "url": "https://i.ytimg.com/vi/Oi2hYcMSYIw/default.jpg",
                        "width": 120,
                        "height": 90
                    },
                    "medium": {
                        "url": "https://i.ytimg.com/vi/Oi2hYcMSYIw/mqdefault.jpg",
                        "width": 320,
                        "height": 180
                    },
                    "high": {
                        "url": "https://i.ytimg.com/vi/Oi2hYcMSYIw/hqdefault.jpg",
                        "width": 480,
                        "height": 360
                    },
                    "standard": {
                        "url": "https://i.ytimg.com/vi/Oi2hYcMSYIw/sddefault.jpg",
                        "width": 640,
                        "height": 480
                    },
                    "maxres": {
                        "url": "https://i.ytimg.com/vi/Oi2hYcMSYIw/maxresdefault.jpg",
                        "width": 1280,
                        "height": 720
                    }
                },
                "channelTitle": "PTEClasses",
                "playlistId": "PLgJ2ytZKfYmigvVTFWtfdQPbXwtO0Wtcu",
                "position": 4,
                "resourceId": {
                    "kind": "youtube#video",
                    "videoId": "Oi2hYcMSYIw"
                },
                "videoOwnerChannelTitle": "PTEClasses",
                "videoOwnerChannelId": "UC9BNqxAvzT-AYaKByLPKJfA"
            },
            "contentDetails": {
                "videoId": "Oi2hYcMSYIw",
                "videoPublishedAt": "2024-03-10T07:38:12Z"
            }
        },
        {
            "kind": "youtube#playlistItem",
            "etag": "An51JYq-Vps3vXrZae_9lw83wHE",
            "id": "UExnSjJ5dFpLZlltaWd2VlRGV3RmZFFQYlh3dE8wV3RjdS4xMkVGQjNCMUM1N0RFNEUx",
            "snippet": {
                "publishedAt": "2024-03-10T07:42:48Z",
                "channelId": "UC9BNqxAvzT-AYaKByLPKJfA",
                "title": "Chaos Poem",
                "description": "",
                "thumbnails": {
                    "default": {
                        "url": "https://i.ytimg.com/vi/SRhxocKtA-I/default.jpg",
                        "width": 120,
                        "height": 90
                    },
                    "medium": {
                        "url": "https://i.ytimg.com/vi/SRhxocKtA-I/mqdefault.jpg",
                        "width": 320,
                        "height": 180
                    },
                    "high": {
                        "url": "https://i.ytimg.com/vi/SRhxocKtA-I/hqdefault.jpg",
                        "width": 480,
                        "height": 360
                    },
                    "standard": {
                        "url": "https://i.ytimg.com/vi/SRhxocKtA-I/sddefault.jpg",
                        "width": 640,
                        "height": 480
                    },
                    "maxres": {
                        "url": "https://i.ytimg.com/vi/SRhxocKtA-I/maxresdefault.jpg",
                        "width": 1280,
                        "height": 720
                    }
                },
                "channelTitle": "PTEClasses",
                "playlistId": "PLgJ2ytZKfYmigvVTFWtfdQPbXwtO0Wtcu",
                "position": 5,
                "resourceId": {
                    "kind": "youtube#video",
                    "videoId": "SRhxocKtA-I"
                },
                "videoOwnerChannelTitle": "PTEClasses",
                "videoOwnerChannelId": "UC9BNqxAvzT-AYaKByLPKJfA"
            },
            "contentDetails": {
                "videoId": "SRhxocKtA-I",
                "videoPublishedAt": "2024-03-10T07:38:09Z"
            }
        },
        {
            "kind": "youtube#playlistItem",
            "etag": "dtxNe2pKL8jNJhRHjDCLZRD9Xas",
            "id": "UExnSjJ5dFpLZlltaWd2VlRGV3RmZFFQYlh3dE8wV3RjdS41MzJCQjBCNDIyRkJDN0VD",
            "snippet": {
                "publishedAt": "2024-03-10T07:42:48Z",
                "channelId": "UC9BNqxAvzT-AYaKByLPKJfA",
                "title": "How To Avoid Spelling Mistakes",
                "description": "",
                "thumbnails": {
                    "default": {
                        "url": "https://i.ytimg.com/vi/UF-Jwqzrgqg/default.jpg",
                        "width": 120,
                        "height": 90
                    },
                    "medium": {
                        "url": "https://i.ytimg.com/vi/UF-Jwqzrgqg/mqdefault.jpg",
                        "width": 320,
                        "height": 180
                    },
                    "high": {
                        "url": "https://i.ytimg.com/vi/UF-Jwqzrgqg/hqdefault.jpg",
                        "width": 480,
                        "height": 360
                    },
                    "standard": {
                        "url": "https://i.ytimg.com/vi/UF-Jwqzrgqg/sddefault.jpg",
                        "width": 640,
                        "height": 480
                    },
                    "maxres": {
                        "url": "https://i.ytimg.com/vi/UF-Jwqzrgqg/maxresdefault.jpg",
                        "width": 1280,
                        "height": 720
                    }
                },
                "channelTitle": "PTEClasses",
                "playlistId": "PLgJ2ytZKfYmigvVTFWtfdQPbXwtO0Wtcu",
                "position": 6,
                "resourceId": {
                    "kind": "youtube#video",
                    "videoId": "UF-Jwqzrgqg"
                },
                "videoOwnerChannelTitle": "PTEClasses",
                "videoOwnerChannelId": "UC9BNqxAvzT-AYaKByLPKJfA"
            },
            "contentDetails": {
                "videoId": "UF-Jwqzrgqg",
                "videoPublishedAt": "2024-03-10T07:38:37Z"
            }
        },
        {
            "kind": "youtube#playlistItem",
            "etag": "zd4l3f5gFgPw2Tw3fdeUiz237qw",
            "id": "UExnSjJ5dFpLZlltaWd2VlRGV3RmZFFQYlh3dE8wV3RjdS5DQUNERDQ2NkIzRUQxNTY1",
            "snippet": {
                "publishedAt": "2024-03-10T07:42:48Z",
                "channelId": "UC9BNqxAvzT-AYaKByLPKJfA",
                "title": "FOLLOW SMART TO DO READING",
                "description": "",
                "thumbnails": {
                    "default": {
                        "url": "https://i.ytimg.com/vi/Wjg6G_JSOU0/default.jpg",
                        "width": 120,
                        "height": 90
                    },
                    "medium": {
                        "url": "https://i.ytimg.com/vi/Wjg6G_JSOU0/mqdefault.jpg",
                        "width": 320,
                        "height": 180
                    },
                    "high": {
                        "url": "https://i.ytimg.com/vi/Wjg6G_JSOU0/hqdefault.jpg",
                        "width": 480,
                        "height": 360
                    },
                    "standard": {
                        "url": "https://i.ytimg.com/vi/Wjg6G_JSOU0/sddefault.jpg",
                        "width": 640,
                        "height": 480
                    },
                    "maxres": {
                        "url": "https://i.ytimg.com/vi/Wjg6G_JSOU0/maxresdefault.jpg",
                        "width": 1280,
                        "height": 720
                    }
                },
                "channelTitle": "PTEClasses",
                "playlistId": "PLgJ2ytZKfYmigvVTFWtfdQPbXwtO0Wtcu",
                "position": 7,
                "resourceId": {
                    "kind": "youtube#video",
                    "videoId": "Wjg6G_JSOU0"
                },
                "videoOwnerChannelTitle": "PTEClasses",
                "videoOwnerChannelId": "UC9BNqxAvzT-AYaKByLPKJfA"
            },
            "contentDetails": {
                "videoId": "Wjg6G_JSOU0",
                "videoPublishedAt": "2024-03-10T07:38:18Z"
            }
        },
        {
            "kind": "youtube#playlistItem",
            "etag": "EsQTscM0C3h7kwVW3WtKbOZ9MJU",
            "id": "UExnSjJ5dFpLZlltaWd2VlRGV3RmZFFQYlh3dE8wV3RjdS45NDk1REZENzhEMzU5MDQz",
            "snippet": {
                "publishedAt": "2024-03-10T07:42:48Z",
                "channelId": "UC9BNqxAvzT-AYaKByLPKJfA",
                "title": "Grammar Power Session Day 2",
                "description": "",
                "thumbnails": {
                    "default": {
                        "url": "https://i.ytimg.com/vi/_Ib12q2oT74/default.jpg",
                        "width": 120,
                        "height": 90
                    },
                    "medium": {
                        "url": "https://i.ytimg.com/vi/_Ib12q2oT74/mqdefault.jpg",
                        "width": 320,
                        "height": 180
                    },
                    "high": {
                        "url": "https://i.ytimg.com/vi/_Ib12q2oT74/hqdefault.jpg",
                        "width": 480,
                        "height": 360
                    },
                    "standard": {
                        "url": "https://i.ytimg.com/vi/_Ib12q2oT74/sddefault.jpg",
                        "width": 640,
                        "height": 480
                    },
                    "maxres": {
                        "url": "https://i.ytimg.com/vi/_Ib12q2oT74/maxresdefault.jpg",
                        "width": 1280,
                        "height": 720
                    }
                },
                "channelTitle": "PTEClasses",
                "playlistId": "PLgJ2ytZKfYmigvVTFWtfdQPbXwtO0Wtcu",
                "position": 8,
                "resourceId": {
                    "kind": "youtube#video",
                    "videoId": "_Ib12q2oT74"
                },
                "videoOwnerChannelTitle": "PTEClasses",
                "videoOwnerChannelId": "UC9BNqxAvzT-AYaKByLPKJfA"
            },
            "contentDetails": {
                "videoId": "_Ib12q2oT74",
                "videoPublishedAt": "2024-03-10T07:38:27Z"
            }
        },
        {
            "kind": "youtube#playlistItem",
            "etag": "S0uxMMW59HoaiT_u0YWeTJPQ-Lo",
            "id": "UExnSjJ5dFpLZlltaWd2VlRGV3RmZFFQYlh3dE8wV3RjdS5GNjNDRDREMDQxOThCMDQ2",
            "snippet": {
                "publishedAt": "2024-03-10T07:42:48Z",
                "channelId": "UC9BNqxAvzT-AYaKByLPKJfA",
                "title": "Listening Module",
                "description": "",
                "thumbnails": {
                    "default": {
                        "url": "https://i.ytimg.com/vi/xMd8zBN5-s8/default.jpg",
                        "width": 120,
                        "height": 90
                    },
                    "medium": {
                        "url": "https://i.ytimg.com/vi/xMd8zBN5-s8/mqdefault.jpg",
                        "width": 320,
                        "height": 180
                    },
                    "high": {
                        "url": "https://i.ytimg.com/vi/xMd8zBN5-s8/hqdefault.jpg",
                        "width": 480,
                        "height": 360
                    },
                    "standard": {
                        "url": "https://i.ytimg.com/vi/xMd8zBN5-s8/sddefault.jpg",
                        "width": 640,
                        "height": 480
                    },
                    "maxres": {
                        "url": "https://i.ytimg.com/vi/xMd8zBN5-s8/maxresdefault.jpg",
                        "width": 1280,
                        "height": 720
                    }
                },
                "channelTitle": "PTEClasses",
                "playlistId": "PLgJ2ytZKfYmigvVTFWtfdQPbXwtO0Wtcu",
                "position": 9,
                "resourceId": {
                    "kind": "youtube#video",
                    "videoId": "xMd8zBN5-s8"
                },
                "videoOwnerChannelTitle": "PTEClasses",
                "videoOwnerChannelId": "UC9BNqxAvzT-AYaKByLPKJfA"
            },
            "contentDetails": {
                "videoId": "xMd8zBN5-s8",
                "videoPublishedAt": "2024-03-10T07:38:47Z"
            }
        },
        {
            "kind": "youtube#playlistItem",
            "etag": "PD_RgCAn4ejbqVbrWDziooioEPg",
            "id": "UExnSjJ5dFpLZlltaWd2VlRGV3RmZFFQYlh3dE8wV3RjdS40NzZCMERDMjVEN0RFRThB",
            "snippet": {
                "publishedAt": "2024-03-10T07:50:42Z",
                "channelId": "UC9BNqxAvzT-AYaKByLPKJfA",
                "title": "Practice Reorder Paragraph",
                "description": "",
                "thumbnails": {
                    "default": {
                        "url": "https://i.ytimg.com/vi/ZyM9AQ359JY/default.jpg",
                        "width": 120,
                        "height": 90
                    },
                    "medium": {
                        "url": "https://i.ytimg.com/vi/ZyM9AQ359JY/mqdefault.jpg",
                        "width": 320,
                        "height": 180
                    },
                    "high": {
                        "url": "https://i.ytimg.com/vi/ZyM9AQ359JY/hqdefault.jpg",
                        "width": 480,
                        "height": 360
                    },
                    "standard": {
                        "url": "https://i.ytimg.com/vi/ZyM9AQ359JY/sddefault.jpg",
                        "width": 640,
                        "height": 480
                    },
                    "maxres": {
                        "url": "https://i.ytimg.com/vi/ZyM9AQ359JY/maxresdefault.jpg",
                        "width": 1280,
                        "height": 720
                    }
                },
                "channelTitle": "PTEClasses",
                "playlistId": "PLgJ2ytZKfYmigvVTFWtfdQPbXwtO0Wtcu",
                "position": 10,
                "resourceId": {
                    "kind": "youtube#video",
                    "videoId": "ZyM9AQ359JY"
                },
                "videoOwnerChannelTitle": "PTEClasses",
                "videoOwnerChannelId": "UC9BNqxAvzT-AYaKByLPKJfA"
            },
            "contentDetails": {
                "videoId": "ZyM9AQ359JY",
                "videoPublishedAt": "2024-03-10T07:41:05Z"
            }
        },
        {
            "kind": "youtube#playlistItem",
            "etag": "pD5jsv1bJa2PuBDUiJYjN8a7ucQ",
            "id": "UExnSjJ5dFpLZlltaWd2VlRGV3RmZFFQYlh3dE8wV3RjdS5EMEEwRUY5M0RDRTU3NDJC",
            "snippet": {
                "publishedAt": "2024-03-14T08:45:36Z",
                "channelId": "UC9BNqxAvzT-AYaKByLPKJfA",
                "title": "Vocabulary Tips Day 3",
                "description": "",
                "thumbnails": {
                    "default": {
                        "url": "https://i.ytimg.com/vi/9bpwheKq2d4/default.jpg",
                        "width": 120,
                        "height": 90
                    },
                    "medium": {
                        "url": "https://i.ytimg.com/vi/9bpwheKq2d4/mqdefault.jpg",
                        "width": 320,
                        "height": 180
                    },
                    "high": {
                        "url": "https://i.ytimg.com/vi/9bpwheKq2d4/hqdefault.jpg",
                        "width": 480,
                        "height": 360
                    },
                    "standard": {
                        "url": "https://i.ytimg.com/vi/9bpwheKq2d4/sddefault.jpg",
                        "width": 640,
                        "height": 480
                    },
                    "maxres": {
                        "url": "https://i.ytimg.com/vi/9bpwheKq2d4/maxresdefault.jpg",
                        "width": 1280,
                        "height": 720
                    }
                },
                "channelTitle": "PTEClasses",
                "playlistId": "PLgJ2ytZKfYmigvVTFWtfdQPbXwtO0Wtcu",
                "position": 11,
                "resourceId": {
                    "kind": "youtube#video",
                    "videoId": "9bpwheKq2d4"
                },
                "videoOwnerChannelTitle": "PTEClasses",
                "videoOwnerChannelId": "UC9BNqxAvzT-AYaKByLPKJfA"
            },
            "contentDetails": {
                "videoId": "9bpwheKq2d4",
                "videoPublishedAt": "2024-03-14T08:14:49Z"
            }
        },
        {
            "kind": "youtube#playlistItem",
            "etag": "eAmTy2uIAb72HrfUGHYrA4DENNM",
            "id": "UExnSjJ5dFpLZlltaWd2VlRGV3RmZFFQYlh3dE8wV3RjdS45ODRDNTg0QjA4NkFBNkQy",
            "snippet": {
                "publishedAt": "2024-03-14T08:45:36Z",
                "channelId": "UC9BNqxAvzT-AYaKByLPKJfA",
                "title": "Vocabulary Tips Day 1 Root Words",
                "description": "",
                "thumbnails": {
                    "default": {
                        "url": "https://i.ytimg.com/vi/EQmnTBl5g6Y/default.jpg",
                        "width": 120,
                        "height": 90
                    },
                    "medium": {
                        "url": "https://i.ytimg.com/vi/EQmnTBl5g6Y/mqdefault.jpg",
                        "width": 320,
                        "height": 180
                    },
                    "high": {
                        "url": "https://i.ytimg.com/vi/EQmnTBl5g6Y/hqdefault.jpg",
                        "width": 480,
                        "height": 360
                    },
                    "standard": {
                        "url": "https://i.ytimg.com/vi/EQmnTBl5g6Y/sddefault.jpg",
                        "width": 640,
                        "height": 480
                    },
                    "maxres": {
                        "url": "https://i.ytimg.com/vi/EQmnTBl5g6Y/maxresdefault.jpg",
                        "width": 1280,
                        "height": 720
                    }
                },
                "channelTitle": "PTEClasses",
                "playlistId": "PLgJ2ytZKfYmigvVTFWtfdQPbXwtO0Wtcu",
                "position": 12,
                "resourceId": {
                    "kind": "youtube#video",
                    "videoId": "EQmnTBl5g6Y"
                },
                "videoOwnerChannelTitle": "PTEClasses",
                "videoOwnerChannelId": "UC9BNqxAvzT-AYaKByLPKJfA"
            },
            "contentDetails": {
                "videoId": "EQmnTBl5g6Y",
                "videoPublishedAt": "2024-03-14T08:14:43Z"
            }
        },
        {
            "kind": "youtube#playlistItem",
            "etag": "dQbZmo0yHiszg7QrSh_Qz7wMwyw",
            "id": "UExnSjJ5dFpLZlltaWd2VlRGV3RmZFFQYlh3dE8wV3RjdS4zMDg5MkQ5MEVDMEM1NTg2",
            "snippet": {
                "publishedAt": "2024-03-14T08:45:36Z",
                "channelId": "UC9BNqxAvzT-AYaKByLPKJfA",
                "title": "Speaking Module",
                "description": "",
                "thumbnails": {
                    "default": {
                        "url": "https://i.ytimg.com/vi/EpseShNF-C0/default.jpg",
                        "width": 120,
                        "height": 90
                    },
                    "medium": {
                        "url": "https://i.ytimg.com/vi/EpseShNF-C0/mqdefault.jpg",
                        "width": 320,
                        "height": 180
                    },
                    "high": {
                        "url": "https://i.ytimg.com/vi/EpseShNF-C0/hqdefault.jpg",
                        "width": 480,
                        "height": 360
                    },
                    "standard": {
                        "url": "https://i.ytimg.com/vi/EpseShNF-C0/sddefault.jpg",
                        "width": 640,
                        "height": 480
                    },
                    "maxres": {
                        "url": "https://i.ytimg.com/vi/EpseShNF-C0/maxresdefault.jpg",
                        "width": 1280,
                        "height": 720
                    }
                },
                "channelTitle": "PTEClasses",
                "playlistId": "PLgJ2ytZKfYmigvVTFWtfdQPbXwtO0Wtcu",
                "position": 13,
                "resourceId": {
                    "kind": "youtube#video",
                    "videoId": "EpseShNF-C0"
                },
                "videoOwnerChannelTitle": "PTEClasses",
                "videoOwnerChannelId": "UC9BNqxAvzT-AYaKByLPKJfA"
            },
            "contentDetails": {
                "videoId": "EpseShNF-C0",
                "videoPublishedAt": "2024-03-14T08:14:18Z"
            }
        },
        {
            "kind": "youtube#playlistItem",
            "etag": "dvLhlSda2cB970ibM9dkJbNYdoM",
            "id": "UExnSjJ5dFpLZlltaWd2VlRGV3RmZFFQYlh3dE8wV3RjdS41Mzk2QTAxMTkzNDk4MDhF",
            "snippet": {
                "publishedAt": "2024-03-14T08:45:36Z",
                "channelId": "UC9BNqxAvzT-AYaKByLPKJfA",
                "title": "Tips And Tricks To Do Re Order",
                "description": "",
                "thumbnails": {
                    "default": {
                        "url": "https://i.ytimg.com/vi/OwswVEOAfIo/default.jpg",
                        "width": 120,
                        "height": 90
                    },
                    "medium": {
                        "url": "https://i.ytimg.com/vi/OwswVEOAfIo/mqdefault.jpg",
                        "width": 320,
                        "height": 180
                    },
                    "high": {
                        "url": "https://i.ytimg.com/vi/OwswVEOAfIo/hqdefault.jpg",
                        "width": 480,
                        "height": 360
                    },
                    "standard": {
                        "url": "https://i.ytimg.com/vi/OwswVEOAfIo/sddefault.jpg",
                        "width": 640,
                        "height": 480
                    },
                    "maxres": {
                        "url": "https://i.ytimg.com/vi/OwswVEOAfIo/maxresdefault.jpg",
                        "width": 1280,
                        "height": 720
                    }
                },
                "channelTitle": "PTEClasses",
                "playlistId": "PLgJ2ytZKfYmigvVTFWtfdQPbXwtO0Wtcu",
                "position": 14,
                "resourceId": {
                    "kind": "youtube#video",
                    "videoId": "OwswVEOAfIo"
                },
                "videoOwnerChannelTitle": "PTEClasses",
                "videoOwnerChannelId": "UC9BNqxAvzT-AYaKByLPKJfA"
            },
            "contentDetails": {
                "videoId": "OwswVEOAfIo",
                "videoPublishedAt": "2024-03-14T08:14:35Z"
            }
        },
        {
            "kind": "youtube#playlistItem",
            "etag": "sydwhUUWBxVuds4BxsVCg7DWv8s",
            "id": "UExnSjJ5dFpLZlltaWd2VlRGV3RmZFFQYlh3dE8wV3RjdS5EQUE1NTFDRjcwMDg0NEMz",
            "snippet": {
                "publishedAt": "2024-03-14T08:45:36Z",
                "channelId": "UC9BNqxAvzT-AYaKByLPKJfA",
                "title": "Strategies Of Reading Filups",
                "description": "",
                "thumbnails": {
                    "default": {
                        "url": "https://i.ytimg.com/vi/cPBSJrWTl78/default.jpg",
                        "width": 120,
                        "height": 90
                    },
                    "medium": {
                        "url": "https://i.ytimg.com/vi/cPBSJrWTl78/mqdefault.jpg",
                        "width": 320,
                        "height": 180
                    },
                    "high": {
                        "url": "https://i.ytimg.com/vi/cPBSJrWTl78/hqdefault.jpg",
                        "width": 480,
                        "height": 360
                    },
                    "standard": {
                        "url": "https://i.ytimg.com/vi/cPBSJrWTl78/sddefault.jpg",
                        "width": 640,
                        "height": 480
                    },
                    "maxres": {
                        "url": "https://i.ytimg.com/vi/cPBSJrWTl78/maxresdefault.jpg",
                        "width": 1280,
                        "height": 720
                    }
                },
                "channelTitle": "PTEClasses",
                "playlistId": "PLgJ2ytZKfYmigvVTFWtfdQPbXwtO0Wtcu",
                "position": 15,
                "resourceId": {
                    "kind": "youtube#video",
                    "videoId": "cPBSJrWTl78"
                },
                "videoOwnerChannelTitle": "PTEClasses",
                "videoOwnerChannelId": "UC9BNqxAvzT-AYaKByLPKJfA"
            },
            "contentDetails": {
                "videoId": "cPBSJrWTl78",
                "videoPublishedAt": "2024-03-14T08:14:29Z"
            }
        },
        {
            "kind": "youtube#playlistItem",
            "etag": "n1_aPTMxiTJ0kBbY-TsV_D9GZdU",
            "id": "UExnSjJ5dFpLZlltaWd2VlRGV3RmZFFQYlh3dE8wV3RjdS41QTY1Q0UxMTVCODczNThE",
            "snippet": {
                "publishedAt": "2024-03-14T08:45:36Z",
                "channelId": "UC9BNqxAvzT-AYaKByLPKJfA",
                "title": "Writing Power Session Day 2",
                "description": "",
                "thumbnails": {
                    "default": {
                        "url": "https://i.ytimg.com/vi/i3s-2FyFnDM/default.jpg",
                        "width": 120,
                        "height": 90
                    },
                    "medium": {
                        "url": "https://i.ytimg.com/vi/i3s-2FyFnDM/mqdefault.jpg",
                        "width": 320,
                        "height": 180
                    },
                    "high": {
                        "url": "https://i.ytimg.com/vi/i3s-2FyFnDM/hqdefault.jpg",
                        "width": 480,
                        "height": 360
                    },
                    "standard": {
                        "url": "https://i.ytimg.com/vi/i3s-2FyFnDM/sddefault.jpg",
                        "width": 640,
                        "height": 480
                    },
                    "maxres": {
                        "url": "https://i.ytimg.com/vi/i3s-2FyFnDM/maxresdefault.jpg",
                        "width": 1280,
                        "height": 720
                    }
                },
                "channelTitle": "PTEClasses",
                "playlistId": "PLgJ2ytZKfYmigvVTFWtfdQPbXwtO0Wtcu",
                "position": 16,
                "resourceId": {
                    "kind": "youtube#video",
                    "videoId": "i3s-2FyFnDM"
                },
                "videoOwnerChannelTitle": "PTEClasses",
                "videoOwnerChannelId": "UC9BNqxAvzT-AYaKByLPKJfA"
            },
            "contentDetails": {
                "videoId": "i3s-2FyFnDM",
                "videoPublishedAt": "2024-03-14T08:15:05Z"
            }
        },
        {
            "kind": "youtube#playlistItem",
            "etag": "fbotnCVe-k0R7YbznPmiTYjWbSY",
            "id": "UExnSjJ5dFpLZlltaWd2VlRGV3RmZFFQYlh3dE8wV3RjdS4yMUQyQTQzMjRDNzMyQTMy",
            "snippet": {
                "publishedAt": "2024-03-14T08:45:36Z",
                "channelId": "UC9BNqxAvzT-AYaKByLPKJfA",
                "title": "Writing Module",
                "description": "",
                "thumbnails": {
                    "default": {
                        "url": "https://i.ytimg.com/vi/wUf2MZM0N6o/default.jpg",
                        "width": 120,
                        "height": 90
                    },
                    "medium": {
                        "url": "https://i.ytimg.com/vi/wUf2MZM0N6o/mqdefault.jpg",
                        "width": 320,
                        "height": 180
                    },
                    "high": {
                        "url": "https://i.ytimg.com/vi/wUf2MZM0N6o/hqdefault.jpg",
                        "width": 480,
                        "height": 360
                    },
                    "standard": {
                        "url": "https://i.ytimg.com/vi/wUf2MZM0N6o/sddefault.jpg",
                        "width": 640,
                        "height": 480
                    },
                    "maxres": {
                        "url": "https://i.ytimg.com/vi/wUf2MZM0N6o/maxresdefault.jpg",
                        "width": 1280,
                        "height": 720
                    }
                },
                "channelTitle": "PTEClasses",
                "playlistId": "PLgJ2ytZKfYmigvVTFWtfdQPbXwtO0Wtcu",
                "position": 17,
                "resourceId": {
                    "kind": "youtube#video",
                    "videoId": "wUf2MZM0N6o"
                },
                "videoOwnerChannelTitle": "PTEClasses",
                "videoOwnerChannelId": "UC9BNqxAvzT-AYaKByLPKJfA"
            },
            "contentDetails": {
                "videoId": "wUf2MZM0N6o",
                "videoPublishedAt": "2024-03-14T08:14:55Z"
            }
        },
        {
            "kind": "youtube#playlistItem",
            "etag": "59RhWxEPx1AzldgokT3vg4gdBGs",
            "id": "UExnSjJ5dFpLZlltaWd2VlRGV3RmZFFQYlh3dE8wV3RjdS45RTgxNDRBMzUwRjQ0MDhC",
            "snippet": {
                "publishedAt": "2024-03-14T08:45:36Z",
                "channelId": "UC9BNqxAvzT-AYaKByLPKJfA",
                "title": "Vocab Tips",
                "description": "",
                "thumbnails": {
                    "default": {
                        "url": "https://i.ytimg.com/vi/ziPbuyUo3Uk/default.jpg",
                        "width": 120,
                        "height": 90
                    },
                    "medium": {
                        "url": "https://i.ytimg.com/vi/ziPbuyUo3Uk/mqdefault.jpg",
                        "width": 320,
                        "height": 180
                    },
                    "high": {
                        "url": "https://i.ytimg.com/vi/ziPbuyUo3Uk/hqdefault.jpg",
                        "width": 480,
                        "height": 360
                    },
                    "standard": {
                        "url": "https://i.ytimg.com/vi/ziPbuyUo3Uk/sddefault.jpg",
                        "width": 640,
                        "height": 480
                    }
                },
                "channelTitle": "PTEClasses",
                "playlistId": "PLgJ2ytZKfYmigvVTFWtfdQPbXwtO0Wtcu",
                "position": 18,
                "resourceId": {
                    "kind": "youtube#video",
                    "videoId": "ziPbuyUo3Uk"
                },
                "videoOwnerChannelTitle": "PTEClasses",
                "videoOwnerChannelId": "UC9BNqxAvzT-AYaKByLPKJfA"
            },
            "contentDetails": {
                "videoId": "ziPbuyUo3Uk",
                "videoPublishedAt": "2024-03-14T08:14:39Z"
            }
        },
        {
            "kind": "youtube#playlistItem",
            "etag": "WVNI4FKMrV8dn7tYY7T4WLhksKo",
            "id": "UExnSjJ5dFpLZlltaWd2VlRGV3RmZFFQYlh3dE8wV3RjdS5ENDU4Q0M4RDExNzM1Mjcy",
            "snippet": {
                "publishedAt": "2024-03-16T04:21:20Z",
                "channelId": "UC9BNqxAvzT-AYaKByLPKJfA",
                "title": "Pronounciation Tips Silent Letters",
                "description": "",
                "thumbnails": {
                    "default": {
                        "url": "https://i.ytimg.com/vi/d_0zMyN1wo0/default.jpg",
                        "width": 120,
                        "height": 90
                    },
                    "medium": {
                        "url": "https://i.ytimg.com/vi/d_0zMyN1wo0/mqdefault.jpg",
                        "width": 320,
                        "height": 180
                    },
                    "high": {
                        "url": "https://i.ytimg.com/vi/d_0zMyN1wo0/hqdefault.jpg",
                        "width": 480,
                        "height": 360
                    },
                    "standard": {
                        "url": "https://i.ytimg.com/vi/d_0zMyN1wo0/sddefault.jpg",
                        "width": 640,
                        "height": 480
                    },
                    "maxres": {
                        "url": "https://i.ytimg.com/vi/d_0zMyN1wo0/maxresdefault.jpg",
                        "width": 1280,
                        "height": 720
                    }
                },
                "channelTitle": "PTEClasses",
                "playlistId": "PLgJ2ytZKfYmigvVTFWtfdQPbXwtO0Wtcu",
                "position": 19,
                "resourceId": {
                    "kind": "youtube#video",
                    "videoId": "d_0zMyN1wo0"
                },
                "videoOwnerChannelTitle": "PTEClasses",
                "videoOwnerChannelId": "UC9BNqxAvzT-AYaKByLPKJfA"
            },
            "contentDetails": {
                "videoId": "d_0zMyN1wo0",
                "videoPublishedAt": "2024-03-14T08:51:47Z"
            }
        },
        {
            "kind": "youtube#playlistItem",
            "etag": "lwqPKS7ZKx-wIQnjfjYcHN4EmFM",
            "id": "UExnSjJ5dFpLZlltaWd2VlRGV3RmZFFQYlh3dE8wV3RjdS4yMDhBMkNBNjRDMjQxQTg1",
            "snippet": {
                "publishedAt": "2024-03-16T04:25:34Z",
                "channelId": "UC9BNqxAvzT-AYaKByLPKJfA",
                "title": "Reading Module",
                "description": "",
                "thumbnails": {
                    "default": {
                        "url": "https://i.ytimg.com/vi/G-cUpPEduBo/default.jpg",
                        "width": 120,
                        "height": 90
                    },
                    "medium": {
                        "url": "https://i.ytimg.com/vi/G-cUpPEduBo/mqdefault.jpg",
                        "width": 320,
                        "height": 180
                    },
                    "high": {
                        "url": "https://i.ytimg.com/vi/G-cUpPEduBo/hqdefault.jpg",
                        "width": 480,
                        "height": 360
                    }
                },
                "channelTitle": "PTEClasses",
                "playlistId": "PLgJ2ytZKfYmigvVTFWtfdQPbXwtO0Wtcu",
                "position": 20,
                "resourceId": {
                    "kind": "youtube#video",
                    "videoId": "G-cUpPEduBo"
                },
                "videoOwnerChannelTitle": "PTEClasses",
                "videoOwnerChannelId": "UC9BNqxAvzT-AYaKByLPKJfA"
            },
            "contentDetails": {
                "videoId": "G-cUpPEduBo",
                "videoPublishedAt": "2024-03-16T04:23:28Z"
            }
        },
        {
            "kind": "youtube#playlistItem",
            "etag": "5QDTKOaBFnsAJlZex0DmAsEluIo",
            "id": "UExnSjJ5dFpLZlltaWd2VlRGV3RmZFFQYlh3dE8wV3RjdS5GM0Q3M0MzMzY5NTJFNTdE",
            "snippet": {
                "publishedAt": "2024-03-16T04:25:34Z",
                "channelId": "UC9BNqxAvzT-AYaKByLPKJfA",
                "title": "Pronunciation Tips Day 4",
                "description": "",
                "thumbnails": {
                    "default": {
                        "url": "https://i.ytimg.com/vi/I4dCDrkWWqA/default.jpg",
                        "width": 120,
                        "height": 90
                    },
                    "medium": {
                        "url": "https://i.ytimg.com/vi/I4dCDrkWWqA/mqdefault.jpg",
                        "width": 320,
                        "height": 180
                    },
                    "high": {
                        "url": "https://i.ytimg.com/vi/I4dCDrkWWqA/hqdefault.jpg",
                        "width": 480,
                        "height": 360
                    },
                    "standard": {
                        "url": "https://i.ytimg.com/vi/I4dCDrkWWqA/sddefault.jpg",
                        "width": 640,
                        "height": 480
                    },
                    "maxres": {
                        "url": "https://i.ytimg.com/vi/I4dCDrkWWqA/maxresdefault.jpg",
                        "width": 1280,
                        "height": 720
                    }
                },
                "channelTitle": "PTEClasses",
                "playlistId": "PLgJ2ytZKfYmigvVTFWtfdQPbXwtO0Wtcu",
                "position": 21,
                "resourceId": {
                    "kind": "youtube#video",
                    "videoId": "I4dCDrkWWqA"
                },
                "videoOwnerChannelTitle": "PTEClasses",
                "videoOwnerChannelId": "UC9BNqxAvzT-AYaKByLPKJfA"
            },
            "contentDetails": {
                "videoId": "I4dCDrkWWqA",
                "videoPublishedAt": "2024-03-16T04:23:18Z"
            }
        },
        {
            "kind": "youtube#playlistItem",
            "etag": "0wZx-mKKVJ9jaMH6p70iGGtwTHs",
            "id": "UExnSjJ5dFpLZlltaWd2VlRGV3RmZFFQYlh3dE8wV3RjdS4zRjM0MkVCRTg0MkYyQTM0",
            "snippet": {
                "publishedAt": "2024-03-16T04:25:34Z",
                "channelId": "UC9BNqxAvzT-AYaKByLPKJfA",
                "title": "Pronounciation Tips Silent Letters",
                "description": "",
                "thumbnails": {
                    "default": {
                        "url": "https://i.ytimg.com/vi/TyT1XbmhepU/default.jpg",
                        "width": 120,
                        "height": 90
                    },
                    "medium": {
                        "url": "https://i.ytimg.com/vi/TyT1XbmhepU/mqdefault.jpg",
                        "width": 320,
                        "height": 180
                    },
                    "high": {
                        "url": "https://i.ytimg.com/vi/TyT1XbmhepU/hqdefault.jpg",
                        "width": 480,
                        "height": 360
                    },
                    "standard": {
                        "url": "https://i.ytimg.com/vi/TyT1XbmhepU/sddefault.jpg",
                        "width": 640,
                        "height": 480
                    },
                    "maxres": {
                        "url": "https://i.ytimg.com/vi/TyT1XbmhepU/maxresdefault.jpg",
                        "width": 1280,
                        "height": 720
                    }
                },
                "channelTitle": "PTEClasses",
                "playlistId": "PLgJ2ytZKfYmigvVTFWtfdQPbXwtO0Wtcu",
                "position": 22,
                "resourceId": {
                    "kind": "youtube#video",
                    "videoId": "TyT1XbmhepU"
                },
                "videoOwnerChannelTitle": "PTEClasses",
                "videoOwnerChannelId": "UC9BNqxAvzT-AYaKByLPKJfA"
            },
            "contentDetails": {
                "videoId": "TyT1XbmhepU",
                "videoPublishedAt": "2024-03-16T04:23:12Z"
            }
        },
        {
            "kind": "youtube#playlistItem",
            "etag": "GNXHS_bGxjJnUu8OMKQ-SA9NsHI",
            "id": "UExnSjJ5dFpLZlltaWd2VlRGV3RmZFFQYlh3dE8wV3RjdS45NzUwQkI1M0UxNThBMkU0",
            "snippet": {
                "publishedAt": "2024-03-16T04:25:34Z",
                "channelId": "UC9BNqxAvzT-AYaKByLPKJfA",
                "title": "Pte Mock Test",
                "description": "",
                "thumbnails": {
                    "default": {
                        "url": "https://i.ytimg.com/vi/VeCn__rpd-M/default.jpg",
                        "width": 120,
                        "height": 90
                    },
                    "medium": {
                        "url": "https://i.ytimg.com/vi/VeCn__rpd-M/mqdefault.jpg",
                        "width": 320,
                        "height": 180
                    },
                    "high": {
                        "url": "https://i.ytimg.com/vi/VeCn__rpd-M/hqdefault.jpg",
                        "width": 480,
                        "height": 360
                    },
                    "standard": {
                        "url": "https://i.ytimg.com/vi/VeCn__rpd-M/sddefault.jpg",
                        "width": 640,
                        "height": 480
                    },
                    "maxres": {
                        "url": "https://i.ytimg.com/vi/VeCn__rpd-M/maxresdefault.jpg",
                        "width": 1280,
                        "height": 720
                    }
                },
                "channelTitle": "PTEClasses",
                "playlistId": "PLgJ2ytZKfYmigvVTFWtfdQPbXwtO0Wtcu",
                "position": 23,
                "resourceId": {
                    "kind": "youtube#video",
                    "videoId": "VeCn__rpd-M"
                },
                "videoOwnerChannelTitle": "PTEClasses",
                "videoOwnerChannelId": "UC9BNqxAvzT-AYaKByLPKJfA"
            },
            "contentDetails": {
                "videoId": "VeCn__rpd-M",
                "videoPublishedAt": "2024-03-16T04:23:23Z"
            }
        },
        {
            "kind": "youtube#playlistItem",
            "etag": "V3Ml-VBcbRsjwYmTn0re0QRMOVQ",
            "id": "UExnSjJ5dFpLZlltaWd2VlRGV3RmZFFQYlh3dE8wV3RjdS5DNzE1RjZEMUZCMjA0RDBB",
            "snippet": {
                "publishedAt": "2024-03-16T04:25:34Z",
                "channelId": "UC9BNqxAvzT-AYaKByLPKJfA",
                "title": "How To Avoid Spelling Mistakes",
                "description": "",
                "thumbnails": {
                    "default": {
                        "url": "https://i.ytimg.com/vi/WULw9ve_wrA/default.jpg",
                        "width": 120,
                        "height": 90
                    },
                    "medium": {
                        "url": "https://i.ytimg.com/vi/WULw9ve_wrA/mqdefault.jpg",
                        "width": 320,
                        "height": 180
                    },
                    "high": {
                        "url": "https://i.ytimg.com/vi/WULw9ve_wrA/hqdefault.jpg",
                        "width": 480,
                        "height": 360
                    },
                    "standard": {
                        "url": "https://i.ytimg.com/vi/WULw9ve_wrA/sddefault.jpg",
                        "width": 640,
                        "height": 480
                    },
                    "maxres": {
                        "url": "https://i.ytimg.com/vi/WULw9ve_wrA/maxresdefault.jpg",
                        "width": 1280,
                        "height": 720
                    }
                },
                "channelTitle": "PTEClasses",
                "playlistId": "PLgJ2ytZKfYmigvVTFWtfdQPbXwtO0Wtcu",
                "position": 24,
                "resourceId": {
                    "kind": "youtube#video",
                    "videoId": "WULw9ve_wrA"
                },
                "videoOwnerChannelTitle": "PTEClasses",
                "videoOwnerChannelId": "UC9BNqxAvzT-AYaKByLPKJfA"
            },
            "contentDetails": {
                "videoId": "WULw9ve_wrA",
                "videoPublishedAt": "2024-03-16T04:22:56Z"
            }
        },
        {
            "kind": "youtube#playlistItem",
            "etag": "gLQG21rZB2wBlPydKi93X8hwr4Q",
            "id": "UExnSjJ5dFpLZlltaWd2VlRGV3RmZFFQYlh3dE8wV3RjdS43MTI1NDIwOTMwQjIxMzNG",
            "snippet": {
                "publishedAt": "2024-03-16T04:25:34Z",
                "channelId": "UC9BNqxAvzT-AYaKByLPKJfA",
                "title": "FOLLOW SMART TO DO READING",
                "description": "",
                "thumbnails": {
                    "default": {
                        "url": "https://i.ytimg.com/vi/Wld0r_nsyWY/default.jpg",
                        "width": 120,
                        "height": 90
                    },
                    "medium": {
                        "url": "https://i.ytimg.com/vi/Wld0r_nsyWY/mqdefault.jpg",
                        "width": 320,
                        "height": 180
                    },
                    "high": {
                        "url": "https://i.ytimg.com/vi/Wld0r_nsyWY/hqdefault.jpg",
                        "width": 480,
                        "height": 360
                    }
                },
                "channelTitle": "PTEClasses",
                "playlistId": "PLgJ2ytZKfYmigvVTFWtfdQPbXwtO0Wtcu",
                "position": 25,
                "resourceId": {
                    "kind": "youtube#video",
                    "videoId": "Wld0r_nsyWY"
                },
                "videoOwnerChannelTitle": "PTEClasses",
                "videoOwnerChannelId": "UC9BNqxAvzT-AYaKByLPKJfA"
            },
            "contentDetails": {
                "videoId": "Wld0r_nsyWY",
                "videoPublishedAt": "2024-03-16T04:22:50Z"
            }
        },
        {
            "kind": "youtube#playlistItem",
            "etag": "TPAm-xtoZ6p_xTrTDNcBH8Q3A6o",
            "id": "UExnSjJ5dFpLZlltaWd2VlRGV3RmZFFQYlh3dE8wV3RjdS5DQ0MyQ0Y4Mzg0M0VGOEYw",
            "snippet": {
                "publishedAt": "2024-03-16T04:25:34Z",
                "channelId": "UC9BNqxAvzT-AYaKByLPKJfA",
                "title": "Reading Tips Suffix",
                "description": "",
                "thumbnails": {
                    "default": {
                        "url": "https://i.ytimg.com/vi/iLZdgS0EdLc/default.jpg",
                        "width": 120,
                        "height": 90
                    },
                    "medium": {
                        "url": "https://i.ytimg.com/vi/iLZdgS0EdLc/mqdefault.jpg",
                        "width": 320,
                        "height": 180
                    },
                    "high": {
                        "url": "https://i.ytimg.com/vi/iLZdgS0EdLc/hqdefault.jpg",
                        "width": 480,
                        "height": 360
                    }
                },
                "channelTitle": "PTEClasses",
                "playlistId": "PLgJ2ytZKfYmigvVTFWtfdQPbXwtO0Wtcu",
                "position": 26,
                "resourceId": {
                    "kind": "youtube#video",
                    "videoId": "iLZdgS0EdLc"
                },
                "videoOwnerChannelTitle": "PTEClasses",
                "videoOwnerChannelId": "UC9BNqxAvzT-AYaKByLPKJfA"
            },
            "contentDetails": {
                "videoId": "iLZdgS0EdLc",
                "videoPublishedAt": "2024-03-16T04:23:37Z"
            }
        },
        {
            "kind": "youtube#playlistItem",
            "etag": "q7VfoQ2tuNMNDkaSDr2sAT26qvk",
            "id": "UExnSjJ5dFpLZlltaWd2VlRGV3RmZFFQYlh3dE8wV3RjdS4yQUE2Q0JEMTk4NTM3RTZC",
            "snippet": {
                "publishedAt": "2024-03-16T04:25:34Z",
                "channelId": "UC9BNqxAvzT-AYaKByLPKJfA",
                "title": "Pronounciation Day 5   Tongue Twisters",
                "description": "",
                "thumbnails": {
                    "default": {
                        "url": "https://i.ytimg.com/vi/nJ3GtmU53Vw/default.jpg",
                        "width": 120,
                        "height": 90
                    },
                    "medium": {
                        "url": "https://i.ytimg.com/vi/nJ3GtmU53Vw/mqdefault.jpg",
                        "width": 320,
                        "height": 180
                    },
                    "high": {
                        "url": "https://i.ytimg.com/vi/nJ3GtmU53Vw/hqdefault.jpg",
                        "width": 480,
                        "height": 360
                    },
                    "standard": {
                        "url": "https://i.ytimg.com/vi/nJ3GtmU53Vw/sddefault.jpg",
                        "width": 640,
                        "height": 480
                    },
                    "maxres": {
                        "url": "https://i.ytimg.com/vi/nJ3GtmU53Vw/maxresdefault.jpg",
                        "width": 1280,
                        "height": 720
                    }
                },
                "channelTitle": "PTEClasses",
                "playlistId": "PLgJ2ytZKfYmigvVTFWtfdQPbXwtO0Wtcu",
                "position": 27,
                "resourceId": {
                    "kind": "youtube#video",
                    "videoId": "nJ3GtmU53Vw"
                },
                "videoOwnerChannelTitle": "PTEClasses",
                "videoOwnerChannelId": "UC9BNqxAvzT-AYaKByLPKJfA"
            },
            "contentDetails": {
                "videoId": "nJ3GtmU53Vw",
                "videoPublishedAt": "2024-03-16T04:23:06Z"
            }
        },
        {
            "kind": "youtube#playlistItem",
            "etag": "SJBw74vpCLgtV1STKkKCW3So8Sg",
            "id": "UExnSjJ5dFpLZlltaWd2VlRGV3RmZFFQYlh3dE8wV3RjdS5DMkU4NTY1QUFGQTYwMDE3",
            "snippet": {
                "publishedAt": "2024-03-16T04:25:51Z",
                "channelId": "UC9BNqxAvzT-AYaKByLPKJfA",
                "title": "Reading Module",
                "description": "",
                "thumbnails": {
                    "default": {
                        "url": "https://i.ytimg.com/vi/G-cUpPEduBo/default.jpg",
                        "width": 120,
                        "height": 90
                    },
                    "medium": {
                        "url": "https://i.ytimg.com/vi/G-cUpPEduBo/mqdefault.jpg",
                        "width": 320,
                        "height": 180
                    },
                    "high": {
                        "url": "https://i.ytimg.com/vi/G-cUpPEduBo/hqdefault.jpg",
                        "width": 480,
                        "height": 360
                    }
                },
                "channelTitle": "PTEClasses",
                "playlistId": "PLgJ2ytZKfYmigvVTFWtfdQPbXwtO0Wtcu",
                "position": 28,
                "resourceId": {
                    "kind": "youtube#video",
                    "videoId": "G-cUpPEduBo"
                },
                "videoOwnerChannelTitle": "PTEClasses",
                "videoOwnerChannelId": "UC9BNqxAvzT-AYaKByLPKJfA"
            },
            "contentDetails": {
                "videoId": "G-cUpPEduBo",
                "videoPublishedAt": "2024-03-16T04:23:28Z"
            }
        },
        {
            "kind": "youtube#playlistItem",
            "etag": "zlDyCepDlLCIw7nAxUXr1fRNSZo",
            "id": "UExnSjJ5dFpLZlltaWd2VlRGV3RmZFFQYlh3dE8wV3RjdS44Mjc5REFBRUE2MTdFRDU0",
            "snippet": {
                "publishedAt": "2024-03-16T04:25:51Z",
                "channelId": "UC9BNqxAvzT-AYaKByLPKJfA",
                "title": "Pronunciation Tips Day 4",
                "description": "",
                "thumbnails": {
                    "default": {
                        "url": "https://i.ytimg.com/vi/I4dCDrkWWqA/default.jpg",
                        "width": 120,
                        "height": 90
                    },
                    "medium": {
                        "url": "https://i.ytimg.com/vi/I4dCDrkWWqA/mqdefault.jpg",
                        "width": 320,
                        "height": 180
                    },
                    "high": {
                        "url": "https://i.ytimg.com/vi/I4dCDrkWWqA/hqdefault.jpg",
                        "width": 480,
                        "height": 360
                    },
                    "standard": {
                        "url": "https://i.ytimg.com/vi/I4dCDrkWWqA/sddefault.jpg",
                        "width": 640,
                        "height": 480
                    },
                    "maxres": {
                        "url": "https://i.ytimg.com/vi/I4dCDrkWWqA/maxresdefault.jpg",
                        "width": 1280,
                        "height": 720
                    }
                },
                "channelTitle": "PTEClasses",
                "playlistId": "PLgJ2ytZKfYmigvVTFWtfdQPbXwtO0Wtcu",
                "position": 29,
                "resourceId": {
                    "kind": "youtube#video",
                    "videoId": "I4dCDrkWWqA"
                },
                "videoOwnerChannelTitle": "PTEClasses",
                "videoOwnerChannelId": "UC9BNqxAvzT-AYaKByLPKJfA"
            },
            "contentDetails": {
                "videoId": "I4dCDrkWWqA",
                "videoPublishedAt": "2024-03-16T04:23:18Z"
            }
        },
        {
            "kind": "youtube#playlistItem",
            "etag": "gxr0fDbkVjfiSuGouoY3ayV3xBk",
            "id": "UExnSjJ5dFpLZlltaWd2VlRGV3RmZFFQYlh3dE8wV3RjdS43NDhFRTgwOTRERTU4Rjg3",
            "snippet": {
                "publishedAt": "2024-03-16T04:25:51Z",
                "channelId": "UC9BNqxAvzT-AYaKByLPKJfA",
                "title": "Pronounciation Tips Silent Letters",
                "description": "",
                "thumbnails": {
                    "default": {
                        "url": "https://i.ytimg.com/vi/TyT1XbmhepU/default.jpg",
                        "width": 120,
                        "height": 90
                    },
                    "medium": {
                        "url": "https://i.ytimg.com/vi/TyT1XbmhepU/mqdefault.jpg",
                        "width": 320,
                        "height": 180
                    },
                    "high": {
                        "url": "https://i.ytimg.com/vi/TyT1XbmhepU/hqdefault.jpg",
                        "width": 480,
                        "height": 360
                    },
                    "standard": {
                        "url": "https://i.ytimg.com/vi/TyT1XbmhepU/sddefault.jpg",
                        "width": 640,
                        "height": 480
                    },
                    "maxres": {
                        "url": "https://i.ytimg.com/vi/TyT1XbmhepU/maxresdefault.jpg",
                        "width": 1280,
                        "height": 720
                    }
                },
                "channelTitle": "PTEClasses",
                "playlistId": "PLgJ2ytZKfYmigvVTFWtfdQPbXwtO0Wtcu",
                "position": 30,
                "resourceId": {
                    "kind": "youtube#video",
                    "videoId": "TyT1XbmhepU"
                },
                "videoOwnerChannelTitle": "PTEClasses",
                "videoOwnerChannelId": "UC9BNqxAvzT-AYaKByLPKJfA"
            },
            "contentDetails": {
                "videoId": "TyT1XbmhepU",
                "videoPublishedAt": "2024-03-16T04:23:12Z"
            }
        },
        {
            "kind": "youtube#playlistItem",
            "etag": "xAk8sOXg_zRGysQZ2ROGo3hZnok",
            "id": "UExnSjJ5dFpLZlltaWd2VlRGV3RmZFFQYlh3dE8wV3RjdS41QUZGQTY5OTE4QTREQUU4",
            "snippet": {
                "publishedAt": "2024-03-16T04:25:51Z",
                "channelId": "UC9BNqxAvzT-AYaKByLPKJfA",
                "title": "Smart Strategies For Reading",
                "description": "",
                "thumbnails": {
                    "default": {
                        "url": "https://i.ytimg.com/vi/VaD3jdmGN9E/default.jpg",
                        "width": 120,
                        "height": 90
                    },
                    "medium": {
                        "url": "https://i.ytimg.com/vi/VaD3jdmGN9E/mqdefault.jpg",
                        "width": 320,
                        "height": 180
                    },
                    "high": {
                        "url": "https://i.ytimg.com/vi/VaD3jdmGN9E/hqdefault.jpg",
                        "width": 480,
                        "height": 360
                    },
                    "standard": {
                        "url": "https://i.ytimg.com/vi/VaD3jdmGN9E/sddefault.jpg",
                        "width": 640,
                        "height": 480
                    },
                    "maxres": {
                        "url": "https://i.ytimg.com/vi/VaD3jdmGN9E/maxresdefault.jpg",
                        "width": 1280,
                        "height": 720
                    }
                },
                "channelTitle": "PTEClasses",
                "playlistId": "PLgJ2ytZKfYmigvVTFWtfdQPbXwtO0Wtcu",
                "position": 31,
                "resourceId": {
                    "kind": "youtube#video",
                    "videoId": "VaD3jdmGN9E"
                },
                "videoOwnerChannelTitle": "PTEClasses",
                "videoOwnerChannelId": "UC9BNqxAvzT-AYaKByLPKJfA"
            },
            "contentDetails": {
                "videoId": "VaD3jdmGN9E",
                "videoPublishedAt": "2024-03-16T04:23:47Z"
            }
        },
        {
            "kind": "youtube#playlistItem",
            "etag": "cGxKrIQlAUHNL9DwMxuLI1VBJd8",
            "id": "UExnSjJ5dFpLZlltaWd2VlRGV3RmZFFQYlh3dE8wV3RjdS4zRDBDOEZDOUM0MDY5NEEz",
            "snippet": {
                "publishedAt": "2024-03-16T04:25:51Z",
                "channelId": "UC9BNqxAvzT-AYaKByLPKJfA",
                "title": "Pte Mock Test",
                "description": "",
                "thumbnails": {
                    "default": {
                        "url": "https://i.ytimg.com/vi/VeCn__rpd-M/default.jpg",
                        "width": 120,
                        "height": 90
                    },
                    "medium": {
                        "url": "https://i.ytimg.com/vi/VeCn__rpd-M/mqdefault.jpg",
                        "width": 320,
                        "height": 180
                    },
                    "high": {
                        "url": "https://i.ytimg.com/vi/VeCn__rpd-M/hqdefault.jpg",
                        "width": 480,
                        "height": 360
                    },
                    "standard": {
                        "url": "https://i.ytimg.com/vi/VeCn__rpd-M/sddefault.jpg",
                        "width": 640,
                        "height": 480
                    },
                    "maxres": {
                        "url": "https://i.ytimg.com/vi/VeCn__rpd-M/maxresdefault.jpg",
                        "width": 1280,
                        "height": 720
                    }
                },
                "channelTitle": "PTEClasses",
                "playlistId": "PLgJ2ytZKfYmigvVTFWtfdQPbXwtO0Wtcu",
                "position": 32,
                "resourceId": {
                    "kind": "youtube#video",
                    "videoId": "VeCn__rpd-M"
                },
                "videoOwnerChannelTitle": "PTEClasses",
                "videoOwnerChannelId": "UC9BNqxAvzT-AYaKByLPKJfA"
            },
            "contentDetails": {
                "videoId": "VeCn__rpd-M",
                "videoPublishedAt": "2024-03-16T04:23:23Z"
            }
        },
        {
            "kind": "youtube#playlistItem",
            "etag": "T9GtirhH0Rh885jq8l2cMCNmMos",
            "id": "UExnSjJ5dFpLZlltaWd2VlRGV3RmZFFQYlh3dE8wV3RjdS5CMEQ2Mjk5NTc3NDZFRUNB",
            "snippet": {
                "publishedAt": "2024-03-16T04:25:51Z",
                "channelId": "UC9BNqxAvzT-AYaKByLPKJfA",
                "title": "How To Avoid Spelling Mistakes",
                "description": "",
                "thumbnails": {
                    "default": {
                        "url": "https://i.ytimg.com/vi/WULw9ve_wrA/default.jpg",
                        "width": 120,
                        "height": 90
                    },
                    "medium": {
                        "url": "https://i.ytimg.com/vi/WULw9ve_wrA/mqdefault.jpg",
                        "width": 320,
                        "height": 180
                    },
                    "high": {
                        "url": "https://i.ytimg.com/vi/WULw9ve_wrA/hqdefault.jpg",
                        "width": 480,
                        "height": 360
                    },
                    "standard": {
                        "url": "https://i.ytimg.com/vi/WULw9ve_wrA/sddefault.jpg",
                        "width": 640,
                        "height": 480
                    },
                    "maxres": {
                        "url": "https://i.ytimg.com/vi/WULw9ve_wrA/maxresdefault.jpg",
                        "width": 1280,
                        "height": 720
                    }
                },
                "channelTitle": "PTEClasses",
                "playlistId": "PLgJ2ytZKfYmigvVTFWtfdQPbXwtO0Wtcu",
                "position": 33,
                "resourceId": {
                    "kind": "youtube#video",
                    "videoId": "WULw9ve_wrA"
                },
                "videoOwnerChannelTitle": "PTEClasses",
                "videoOwnerChannelId": "UC9BNqxAvzT-AYaKByLPKJfA"
            },
            "contentDetails": {
                "videoId": "WULw9ve_wrA",
                "videoPublishedAt": "2024-03-16T04:22:56Z"
            }
        },
        {
            "kind": "youtube#playlistItem",
            "etag": "GgpurFTPEJKAZHpA1gdY_3c2lc0",
            "id": "UExnSjJ5dFpLZlltaWd2VlRGV3RmZFFQYlh3dE8wV3RjdS42MTI4Njc2QjM1RjU1MjlG",
            "snippet": {
                "publishedAt": "2024-03-16T04:25:51Z",
                "channelId": "UC9BNqxAvzT-AYaKByLPKJfA",
                "title": "FOLLOW SMART TO DO READING",
                "description": "",
                "thumbnails": {
                    "default": {
                        "url": "https://i.ytimg.com/vi/Wld0r_nsyWY/default.jpg",
                        "width": 120,
                        "height": 90
                    },
                    "medium": {
                        "url": "https://i.ytimg.com/vi/Wld0r_nsyWY/mqdefault.jpg",
                        "width": 320,
                        "height": 180
                    },
                    "high": {
                        "url": "https://i.ytimg.com/vi/Wld0r_nsyWY/hqdefault.jpg",
                        "width": 480,
                        "height": 360
                    }
                },
                "channelTitle": "PTEClasses",
                "playlistId": "PLgJ2ytZKfYmigvVTFWtfdQPbXwtO0Wtcu",
                "position": 34,
                "resourceId": {
                    "kind": "youtube#video",
                    "videoId": "Wld0r_nsyWY"
                },
                "videoOwnerChannelTitle": "PTEClasses",
                "videoOwnerChannelId": "UC9BNqxAvzT-AYaKByLPKJfA"
            },
            "contentDetails": {
                "videoId": "Wld0r_nsyWY",
                "videoPublishedAt": "2024-03-16T04:22:50Z"
            }
        },
        {
            "kind": "youtube#playlistItem",
            "etag": "ufDVVO0-1LoWTp1WBYK7vG-QgAE",
            "id": "UExnSjJ5dFpLZlltaWd2VlRGV3RmZFFQYlh3dE8wV3RjdS45RjNFMDhGQ0Q2RkFCQTc1",
            "snippet": {
                "publishedAt": "2024-03-16T04:25:51Z",
                "channelId": "UC9BNqxAvzT-AYaKByLPKJfA",
                "title": "Reading Tips Suffix",
                "description": "",
                "thumbnails": {
                    "default": {
                        "url": "https://i.ytimg.com/vi/iLZdgS0EdLc/default.jpg",
                        "width": 120,
                        "height": 90
                    },
                    "medium": {
                        "url": "https://i.ytimg.com/vi/iLZdgS0EdLc/mqdefault.jpg",
                        "width": 320,
                        "height": 180
                    },
                    "high": {
                        "url": "https://i.ytimg.com/vi/iLZdgS0EdLc/hqdefault.jpg",
                        "width": 480,
                        "height": 360
                    }
                },
                "channelTitle": "PTEClasses",
                "playlistId": "PLgJ2ytZKfYmigvVTFWtfdQPbXwtO0Wtcu",
                "position": 35,
                "resourceId": {
                    "kind": "youtube#video",
                    "videoId": "iLZdgS0EdLc"
                },
                "videoOwnerChannelTitle": "PTEClasses",
                "videoOwnerChannelId": "UC9BNqxAvzT-AYaKByLPKJfA"
            },
            "contentDetails": {
                "videoId": "iLZdgS0EdLc",
                "videoPublishedAt": "2024-03-16T04:23:37Z"
            }
        },
        {
            "kind": "youtube#playlistItem",
            "etag": "dY-xObydvQjUW88Ll-Ivy0OnaFU",
            "id": "UExnSjJ5dFpLZlltaWd2VlRGV3RmZFFQYlh3dE8wV3RjdS40QTA3NTU2RkM1QzlCMzYx",
            "snippet": {
                "publishedAt": "2024-03-16T04:25:51Z",
                "channelId": "UC9BNqxAvzT-AYaKByLPKJfA",
                "title": "Pronounciation Day 5   Tongue Twisters",
                "description": "",
                "thumbnails": {
                    "default": {
                        "url": "https://i.ytimg.com/vi/nJ3GtmU53Vw/default.jpg",
                        "width": 120,
                        "height": 90
                    },
                    "medium": {
                        "url": "https://i.ytimg.com/vi/nJ3GtmU53Vw/mqdefault.jpg",
                        "width": 320,
                        "height": 180
                    },
                    "high": {
                        "url": "https://i.ytimg.com/vi/nJ3GtmU53Vw/hqdefault.jpg",
                        "width": 480,
                        "height": 360
                    },
                    "standard": {
                        "url": "https://i.ytimg.com/vi/nJ3GtmU53Vw/sddefault.jpg",
                        "width": 640,
                        "height": 480
                    },
                    "maxres": {
                        "url": "https://i.ytimg.com/vi/nJ3GtmU53Vw/maxresdefault.jpg",
                        "width": 1280,
                        "height": 720
                    }
                },
                "channelTitle": "PTEClasses",
                "playlistId": "PLgJ2ytZKfYmigvVTFWtfdQPbXwtO0Wtcu",
                "position": 36,
                "resourceId": {
                    "kind": "youtube#video",
                    "videoId": "nJ3GtmU53Vw"
                },
                "videoOwnerChannelTitle": "PTEClasses",
                "videoOwnerChannelId": "UC9BNqxAvzT-AYaKByLPKJfA"
            },
            "contentDetails": {
                "videoId": "nJ3GtmU53Vw",
                "videoPublishedAt": "2024-03-16T04:23:06Z"
            }
        }
                ]
        }
    },
    created(){
        this.userInfo=this.$ls.get('user');
        
         //this.loadYoutubeVideos();
         this.loadVideos();
    },
    methods:{
        ...mapActions({
            fetch:'premiumvideo/fetchVideo', 
            saveVideo:'premiumvideo/postLikeDislike',
            deleteVideo:'premiumvideo/postLikeDislike'
        }),
        ...mapGetters({
            GetAll:'premiumvideo/videos',
            saveCountResponse:'premiumvideo/saveCountResponse'
        }),
        playSelectedVideo(selectedItem){
            this.reset();
            console.log(selectedItem);
            this.activeTitle=selectedItem.title;
            this.activeVideo=this.basePath+selectedItem.url+'.mp4#t=03';
            //'https://www.youtube.com/embed/'+selectedItem.contentDetails.videoId+this.extralink; //this.basePath+selectedItem.url;//+'.mp4#t=03'
            this.id=selectedItem.id;
          //  this.getcount();
        },
        async loadYoutubeVideos(){
            const url=`premiumvideo/video/youtubeApiRequest/all`;
            await this.fetch(url).then((data) => {
               // only to fetch youtube data and save in db
                //console.log(data);
                // this.youTubeList=data.items
                // this.activeTitle=this.youTubeList[0].snippet.title;
                // this.activeVideo='https://youtu.be/'+this.youTubeList[0].contentDetails.videoId+'.mp4#t=03';
                // this.id=this.youTubeList.snippet.position;
            }).catch((error) => {
                console.error("Error fetching Videos:", error);
                });
        },
        async loadVideos() {
            debugger
            console.log("load videos")
            await this.fetch("premiumvideo/video/all")
                .then(async () => {
                const response=this.GetAll();
                    console.log("response", response);
                if(response.success==1){
                    this.youTubeList=response.data;
                    this.activeTitle=this.youTubeList[0].title;
                    this.activeVideo=this.basePath+this.youTubeList[0].url+'.mp4#t=03';
                    this.id=this.youTubeList[0].id;
                    await this.getcount();
                }
                else{
                    this.$alert("Issue on data reterival","Error","error");
                }
                })
                .catch((error) => {
                console.error("Error fetching Videos:", error);
                });
        },
        isAdminComment(comment) {
      if(comment === 1) {
        this.isAdmin = true;
      }
        return this.isAdmin && comment;
    },
    reset(){
        this.likes=0;
        this.disLikes=0;
        this.disableLikeButton = false;
        this.disableDislikeButton = false;
        this.userComments=[];
    },
   async deleteComment(commentId){
        console.log(commentId);
        const param = {
                id: commentId,
                date: this.getCurrentDateTime(),
                isActive: 0,
                url:"premiumvideo/comment/UpdateComment"
            };
            this.$confirm("Are you sure you want to remove this comment?",
                              "Confirmation",
                              "question",
                              {
                                confirmButtonText: "Yes"
                              }).then(async ()=>{
                                await this.deleteVideo(param).then(()=>{
                                    const response=this.saveCountResponse();
                                    console.log(response);
                                    this.getcount();
                                });
                              }).catch(() => {
                                return //console.log('cancel')
                              });
           
    },
        async getcount(){
           this.reset();
            const param=`${this.id}--${this.userInfo.user_id}`;
            await this.fetch("premiumvideo/video/"+param)
                .then(() => {
                const response=this.GetAll();
                    console.log("response", response);
                if(response.success==1){
                    this.likes = (response.data[0].likeCount==null)?0:response.data[0].likeCount;
                    this.disLikes = (response.data[0].dislikeCount==null)?0:response.data[0].dislikeCount;
                    this.userComments=response.comments;
                   if(response.userVideoStatus.length>0){
                    
                    if (response.userVideoStatus[0].status === "like") {
                        console.log('like')
                        this.disableLikeButton = true;
                        this.disableDislikeButton = false;
                        } else if (response.userVideoStatus[0].status === "dislike") {
                            console.log('dislike')
                        this.disableLikeButton = false;
                        this.disableDislikeButton = true;
                        } else {
                            console.log('nothing')
                        this.disableLikeButton = false;
                        this.disableDislikeButton = false;
                        }
                   } 
                   else {
                        this.disableLikeButton = false;
                        this.disableDislikeButton = false;
                        }
                }
                else{
                    this.$alert("Issue on data reterival","Error","error");
                }
                })
                .catch((error) => {
                console.error("Error fetching Videos:", error);
                });
        },
        async submitData() {
                const enteredComment = this.commentInput;
                if (enteredComment === "") {
                    this.$alert("Invalid input.","Error")
                    return;
                }
                const admin = (this.userInfo.is_admin=='T')?true:false;
                const newData = {
                    userId: this.userInfo.user_id,
                    comment: enteredComment,
                    date: this.getCurrentDateTime(),
                    isActive: 1,
                    videoId: +this.id,
                    isAdmin: admin,
                    url:"premiumvideo/comment/SaveComment"
                };
                //console.log("newData",newData);
                await this.saveVideo(newData).then(()=>{
                    const response=this.saveCountResponse();
                    console.log(response);
                    this.getcount();
                });
                this.commentInput = "";
                },
        async saveCountLike(status,btnStatus) {
            if(btnStatus) return;
            const param = {
                userId: this.userInfo.user_id,
                videoId: +this.id,
                date: this.getCurrentDateTime(),
                isActive: 1,
                status: status,
                url:"premiumvideo/video/SaveVideoCount"
            };
            console.log(param)
            await this.saveVideo(param).then(()=>{
                const response=this.saveCountResponse();
                console.log(response);
                if(status=='like'){
                    this.likes=this.likes+1;
                    this.disLikes=(this.disLikes>0)?this.disLikes-1:this.disLikes;
                    this.disableDislikeButton=false;
                    this.disableLikeButton=true;
                }
                else{
                    this.disLikes=this.disLikes+1;
                    this.likes=(this.likes>0)?this.likes-1:this.likes;
                    this.disableDislikeButton=true;
                    this.disableLikeButton=false;
                }
            })
            },
            getCurrentDateTime() {
      const currentDateTime = new Date();
      const year = currentDateTime.getFullYear();
      const month = ("0" + (currentDateTime.getMonth() + 1)).slice(-2);
      const day = ("0" + currentDateTime.getDate()).slice(-2);
      const hours = ("0" + currentDateTime.getHours()).slice(-2);
      const minutes = ("0" + currentDateTime.getMinutes()).slice(-2);
      const seconds = ("0" + currentDateTime.getSeconds()).slice(-2);
      const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
      return formattedDateTime;
    },
    }
    
}
</script>
<style>

video{
    border-radius: 10px;
}
#playOverlay .bg-light{
    opacity: 0!important;
    backdrop-filter: none !important;;
    background-color: transparent!important; 
}
.likeSpan
{
    min-width: 80px;
    display: inline-block;
    background: #dedede;
    text-align: center;
    padding: 5px;
    border-radius: 10px;
    color: black;
}
.likeSpan span{
    font-size: 20px;
    margin-left: -5px;
    padding-right: 10px;
    padding-left: 5px;
}
.leftBorder{
border-left: 1px solid #262525;
}
.likeSpan .disabled{
    color: #ccc!important;
}
.comment-content {
  flex-grow: 1;
  padding-left: 2px;
} 
.CommentsContainer  input,
.CommentsContainer  textarea {
  display: block;
  width: 100%;
  font: inherit;
  border: 1px solid #ccc;
}

.CommentsContainer  input:focus,
.CommentsContainer  textarea:focus {
  outline: none;
  border-color: #3a0061;
  background-color: #f7ebff;
}
.CommentsContainer {
  border-radius: 12px;
  padding-left: 0;
}
.commentDelete{
    position: absolute;
    right: 15px;
    color: #d34117!important;
}
</style>